export enum Network {
  MAIN = 'f',
  TEST = 't'
}

// an alias, keeping Network for now in case of breaking changes
export enum CoinType {
  MAIN = 'f',
  TEST = 't'
}
